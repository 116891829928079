import { VENDOR_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';

const apiUrl = VENDOR_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const vendorDashmershSetupProviderName = 'vendor-dashmerch-setup';

const vendorDashmershSetupProvider: Omit<
  Required<DataProvider>,
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'getMany'
  | 'custom'
  | 'getList'
  | 'create'
  | 'deleteOne'
  | 'getApiUrl'
  | 'getOne'
> = {
  update: async ({ resource, id, variables }) => {
    try {
      return await httpClient.patch(`vendor/${id}/${resource}`, variables).then((r) => {
        return r.data;
      });
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
	  	This `any` is intened to receipt any type
	  */
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
};

export default vendorDashmershSetupProvider;
