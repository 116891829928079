/**
 * This file is copied from `core-internal-tools`
 * https://bitbucket.org/efishery/internal-tools/src
 */
import axios, { type AxiosInstance } from 'axios';
import { HttpError } from '@refinedev/core';
import { auth } from '@/utils/auth';
import { APP_NAME } from '@/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Disable for now
const errorWrapper = (error: any) => {
  const errorMainMessage = error?.response?.data?.error ?? '';
  const errorDataMessage = error?.response?.data?.message ?? '';

  // Tests whether the string has a pattern of square brackets at the start and end or like a array
  const isArrayLike = /^\[.*\]$/.test(errorDataMessage);

  // Spesification detail error message
  const errorDetailMessage = isArrayLike
    ? errorDataMessage.replace(/[[\]"]/g, '').replace(/precondition /g, '')
    : error?.response?.data?.message;

  const messageError =
    errorMainMessage && errorDetailMessage
      ? `${errorMainMessage} - ${errorDetailMessage}`
      : errorMainMessage ?? errorDetailMessage ?? 'Something went wrong';

  const customError: HttpError = {
    message: messageError,
    statusCode: error?.response?.status ?? 500,
  };

  return Promise.reject(customError);
};

const addRequestInterceptor = (axiosInstance: AxiosInstance, requestHeaders?: Record<string, string>) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      if (config.headers !== undefined) {
        const accessToken = await auth.getAccessToken();

        config.headers['Authorization'] = `Bearer ${accessToken}`;
        config.headers['X-Client-Id'] = APP_NAME;

        if (requestHeaders) {
          Object.keys(requestHeaders).forEach((key) => {
            config.headers[key] = requestHeaders[key];
          });
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

const addResponseInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err?.config;

      if (!originalConfig?.sent && err?.response?.status === 401) {
        originalConfig.sent = true;

        try {
          const { accessToken } = await auth.persistRefresh();
          originalConfig.headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          // do whatever you wanna do with new accessToken here

          return await axiosInstance(originalConfig);
        } catch (err) {
          await auth.logout();
          return errorWrapper(err);
        }
      }

      return errorWrapper(err);
    },
  );
};

type CreateHttpOptions = {
  requestHeaders?: Record<string, string>;
};

export const CreateHttp = (baseURL: string, opts?: CreateHttpOptions) => {
  const http = axios.create({
    baseURL,
  });

  addRequestInterceptor(http, opts?.requestHeaders);
  addResponseInterceptor(http);

  return http;
};
